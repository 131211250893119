<template lang="pug">
    v-slide-x-reverse-transition
         v-container( fluid pa-0 fill-height align-content-start class="tw-relative"  )
                div(v-if="loading" class=" fill-height tw-w-full loading-animation tw-z-10 tw-absolute tw-self-start tw-overflow-hidden tw-mt-1")
                v-row(no-gutters class="tw-border-b tw-border-gray-300 tw-z-0")
                    v-col(class="tw-h-32  pa-0 ma-0 tw-shadow")
                        v-card(tile :loading="loading" class="tw-rounded-none tw-h-32 tw-shadow-inner tw-border-gray-500 px-2 pt-0 pb-2 ")
                            v-container( fluid pa-0 pt-2 ma-0 )
                                v-row(no-gutters)
                                    v-col(v-if="ticket.number" class="text-right tw-items-center tw-font-extrabold tw-text-blue-800")
                                        |ZD \#{{ ticket.number }}
                                        a(:href="'https://xprt.zendesk.com/agent/tickets/' + ticket.number" target="blank")
                                            v-icon(small class="pl-2 pb-1" ) mdi-open-in-new
                                v-row(no-gutters align="start")
                                    v-col
                                        v-row(no-gutters align="start")
                                            v-col(cols="auto" v-if="ticket.company")
                                                v-card(flat v-if="ticket.company.WEBLINK"  :href="ticket.company.WEBLINK | notNull" target="blank" :ripple="false" width="110px" height="80px"  class="tw-p-1 tw-border tw-border-gray-300" align="center"   )
                                                    v-img(v-if="ticket.company.LOGO400" :src="'https://www.xprt.com' + ticket.company.LOGO400 | notNull" )
                                            v-col(class="tw-ml-1")
                                                div()
                                                    span(class=" tw-text-xl tw-font-semibold tw-text-gray-700 ") {{ ticket.requester_name | notNull }}
                                                div(v-if="ticket.company")
                                                    span(class="tw-font-normal tw-text-gray-600" ) {{ ticket.company.COMPANY | notNull }}
                                                div(v-else-if="!loading")
                                                    v-chip(color="red") 
                                                        span( class="tw-font-normal tw-text-red-100") No record found
                v-row( no-gutters align="start" class="fill-height overflow-hidden" )
                    v-col(class=" tw-max-h-main tw-overflow-scroll px-4  ")
                            div(v-for="message in ticket.messages" )
                                div(@click="detailView = !detailView" class="tw-cursor-pointer")
                                    xprt-MktLeads-leadMessage(
                                        :author="message.author_name"
                                        :date="message.created_at"
                                        :public="message.public"
                                        :body="message.html_body"
                                    )
                            div(class=" tw-pb-32")
                        
                    
        
</template>
<script>
export default {
    props: {
        ticketNumber:{
            type: Number,
            default: 0
        }
    },
     data() {
        return {
            items: null,
            tab: null,
            detailView: false,
            ticket: {
                number: '',
                requester_name: '',
                company: {
                    WEBLINK: '',
                    LOGO400: '',
                }

            },
            loading: true,
        }
    },
    watch: {
        ticketNumber: function(){
            this.update()
        }
    },
    mounted() {
        this.update()
    },
    methods: {
        update(){
            this.loading = true
            this.ticket = {}
            this.$http.get(this.$api_url + 'campaigns/ticket_details/' + this.ticketNumber , {
            headers: {
                'OAuth-Token': this.$store.getters.token
              }
            })
            .then(response => {
                this.ticket = response.data
                this.loading = false
            })
        }
 
    },
}
</script>
<style lang="css">


.loading-animation {
    background: linear-gradient(90deg,  rgba(255, 255, 255, 0.4) , rgba(201, 203, 209, 0.7));
    background-size: 300% 300%;
    -webkit-animation: loading-animation 3s ease infinite;
    -moz-animation: loading-animation 3s ease infinite;
    animation: loading-animation 3s ease infinite;

}

@-webkit-keyframes loading-animation {
    0%{background-position:0% 49%}
    50%{background-position:100% 52%}
    100%{background-position:0% 49%}
}
@-moz-keyframes loading-animation {
    0%{background-position:0% 49%}
    50%{background-position:100% 52%}
    100%{background-position:0% 49%}
}
@keyframes loading-animation {
    0%{background-position:0% 49%}
    50%{background-position:100% 52%}
    100%{background-position:0% 49%}
}
</style>