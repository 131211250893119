
<script>
export default {

    data () {
    return {
      x: 0,
      y: 0,
      showTools: false,
      selectedText: ''
    }
  },

  computed: {
    highlightableEl () {
      return this.$slots.default[0].elm
    }
  },

  mounted () {
    window.addEventListener('mouseup', this.onMouseup)
  },

  beforeDestroy () {
    window.removeEventListener('mouseup', this.onMouseup)
  },

  methods: {
    onMouseup () {
      const selection = window.getSelection()
      const startNode = selection.getRangeAt(0).startContainer.parentNode
      const endNode = selection.getRangeAt(0).endContainer.parentNode
      if (!startNode.isSameNode(this.highlightableEl) || !startNode.isSameNode(endNode)) {
        this.showTools = false
        this.$emit('tools', false)
        return
      }
      const { x, y, width } = selection.getRangeAt(0).getBoundingClientRect()
      if (!width || width < 1 ) {
        this.showTools = false
        this.$emit('tools', false)
        return
      }
      this.x = x + (width / 2)
      this.y = y + window.scrollY - 10
      this.showTools = true
      this.$emit('tools', true)
      this.selectedText = selection.toString()
      this.$emit('selection', this)
      return
    },

    handleAction (action) {
      console.log('Action: ' + action)
      this.$emit(action, this.selectedText)
    }
  },
  render(h) {
    return this.$scopedSlots.default(this);
  },

}

</script>