// https://vuex.vuejs.org/en/getters.html
import moment from 'moment'

export default {
  //
  loggedIn (state) {
    return state.token != null
  },
  company (state) {
    return state.app.company
  },
  token (state) {
    return state.token
  },
  refreshToken (state) {
    return state.refreshToken
  },
  isTokenValid (state) {
    const isTokenValid = state.tokenExpires > moment().subtract(2, 'm')
    return isTokenValid
  },
  temp (state) {
    return state.temp
  }, 
  result (state){
    return state.result
  }
}
