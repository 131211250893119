<template>
  <v-container
    pa-0
    ma-0
    fluid
    style="overflow-x: hidden; overflow-y: hidden !important; max-height:450px"
  >
    <v-flex
      style="display: none"
    >
      {{ company }}
    </v-flex>

    <v-layout
      v-if="company_details.description[0].id"
      justify-center
      row

      wrap
      class="light-blue darken-4"
    >
      <v-container
        fluid
        grid-list-md
        class="pt-4  ml-0 mt-0"
      >
        <v-layout
          row
          wrap
          class="tw-py-5"
        >
          <v-flex
            wrap
            row
            xs12
            md2
            class="pa-0 ma-0"
          >
            <v-flex
              d-flex
              justify-center
              pa-0
              ma-0
            >
              <v-card
                :href="company_details.description[0].website"
                target="_blank"
                color="white"
                max-height="100px"
                max-width="150px"
              >
                <v-flex
                  d-flex
                  align-content-center
                >
                  <v-img
                    :src="'https://www.xprt.com/' + company_details.description[0].logo"
                    class=" align-center"
                    contain
                    style="max-height: 90px; max-width:120px"
                  />
                </v-flex>
              </v-card>
            </v-flex>
          </v-flex>
          <v-flex
            d-flex
            xs12
            sm10
            pl-3
          >
            <v-layout
              row
              wrap
            >
              <v-flex
                d-flex
                xs12
                md6

                class="white--text pb-0 headline"
              >
                {{ company_details.description[0].name }}
              </v-flex>
              <v-flex
                d-flex
                xs12
                class="pt-0 ma-0 pl-0 pr-0 pb-0"
              >
                <v-card
                  flat
                  color="transparent"
                  dark
                >
                  <v-card-text
                    class="pt-0 mt-0 justify-center pb-0"
                  >
                    <a
                      :href="'https://www.xprt.com/companies/c-' + company_details.description[0].id"
                      class="white--text"
                      target="_blank"
                    > Storefront </a> | <a
                      :href="'http://sugar.xprt.com/#/Accounts/' + company_details.description[0].idcrm"
                      class="white--text"
                      target="_blank"
                    > SugarCRM </a> |  <a
                      :href="'https://www.environmental-expert.com/backoffice/File_Contactmoment.asp?code=' + company_details.description[0].id"
                      class="white--text"
                      target="_blank"
                    > BackOffice </a>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
    <v-layout
      wrap
      row
    >
      <v-flex
        xs12
        sm3
        md2
        wrap
        ma-0
        pl-4
        pr-4
        d-flex
        class="white"
      >
        <v-layout
          xs12
          wrap
          pa-0
          ma-0
          align-content-start
        >
          <v-flex
            class="pa-0 ma-0"
            xs6
            md12
          >
            <v-icon

              small
            >
              mdi-map-marker
            </v-icon>
            <span class="font-weight-bold pl-1 light-blue--text text--darken-4 subheading">{{ company_details.description[0].location }}</span>
          </v-flex>
          <v-flex
            xs6
            md12

            class="pa-0 ma-0"
          >
            <v-icon
              small
            >
              mdi-domain
            </v-icon>
            <span class="font-weight-bold pl-1 light-blue--text text--darken-4 subheading">{{ company_details.description[0].business_type }}</span>
          </v-flex>
          <template v-if="company_details.description[0].objection">
            <v-flex
              xs12

              class="pa-0 ma-0"
            >
              <v-icon

                small
              >
                mdi-thumb-down
              </v-icon>
              <span class="font-weight-bold  pl-1 light-blue--text text--darken-4 subheading">{{ company_details.description[0].objection }}</span>
            </v-flex>
          </template>
        </v-layout>
      </v-flex>
      <v-flex
        sm9
        md10
        pt-0
        ml-0
        pl-0
        class="grey lighten-4"
      >
        <v-tabs
          background-color="blue-grey lighten-2"
          dark
          grow
          slider-color="red accent-2"
        >
          <v-tab
            key="tab1"
            ripple
          >
            Calls
          </v-tab>
          <v-tab-item
            key="tab1"
          >
            <div
              style="position: relative;"
              class="pa-0 ma-0 grey lighten-4"
            >
              <div
                v-if="interactions.calls && interactions.calls.length > 0"
                class="pa-0 ma-0 pt-2"
                style="height:350px; overflow:auto; overflow-x:hidden; box-shadow: inset 1px 0 2px 0 rgba(156,156,156,0.50);"
              >
                <template
                  v-for="(item, index) in interactions.calls"
                >
                  <v-layout
                    :key="item.id"
                    xs12
                    wrap
                    pl-4
                    pr-4
                  >
                    <v-flex
                      xs12
                      d-flex
                      pb-0
                    >
                      <span class="font-weight-medium text-uppercase subheading text--darken-4 grey--text"> {{ item.name }} </span>
                    </v-flex>
                    <v-flex
                      v-if="item.description"
                      d-flex
                      xs12
                      pt-0
                    >
                      <span class=" font-italic body-1 text--darken-3 grey--text"> {{ item.description }} </span>
                    </v-flex>
                    <v-flex
                      d-flex
                      xs12
                      pt-0
                      body-1
                      font-weight-light
                      font-italic
                    >
                      <span class="font-italic body-1 text--darken-3 grey--text"> {{ humanizeDate(item.date) }} by {{ item.user }}</span>
                    </v-flex>
                  </v-layout>

                  <v-divider
                    v-if="index + 1 < interactions.calls.length"
                    :key="index"
                    class="grey lighten-2"
                    light
                    indent
                  />
                </template>
              </div>

              <div
                v-else
                class="pa-0 ma-0 pt-2 grey lighten-4"
                style="height:350px; overflow:hidden; overflow-x:hidden; box-shadow: inset 1px 0 2px 0 rgba(156,156,156,0.50);"
              >
                <v-flex
                  d-block
                  fill-height
                  justify-center
                >
                  <v-flex
                    d-flex
                    justify-center
                  >
                    <v-icon x-large>
                      mdi-message-alert-outline
                    </v-icon>
                  </v-flex>
                  <v-flex
                    d-flex
                    justify-center
                  >
                    No calls found on this account
                  </v-flex>
                </v-flex>
              </div>
            </div>
          </v-tab-item>
          <v-tab
            key="tab2"
            ripple
          >
            Inquiries
          </v-tab>
          <v-tab-item
            key="tab2"
          >
            <div
              style="position: relative;"
              class="pa-0 ma-0 grey lighten-4"
            >
              <div
                v-if="inquiries && inquiries.length > 0 "
                class="pa-0 ma-0 pt-2"
                style="height:350px; overflow:auto; overflow-x:hidden; box-shadow: inset 1px 0 2px 0 rgba(156,156,156,0.50);"
              >
                <template
                  v-for="(item, index) in inquiries"
                >
                  <v-layout
                    :key="item.id"
                    xs12
                    wrap
                    pl-4
                    pr-4
                  >
                    <v-flex
                      xs12
                      d-flex
                      pb-0
                    >
                      <span class="font-weight-medium text-uppercase subheading grey--text text--darken-4"> {{ item.subject }} </span>
                    </v-flex>
                    <v-flex
                      v-if="item.inquiry"
                      d-flex
                      xs12
                      pt-0
                    >
                      <span class=" font-italic body-1 text--darken-3 grey--text"> {{ item.inquiry }} </span>
                    </v-flex>
                    <v-flex
                      d-flex
                      xs12
                      pt-0
                      body-1
                      font-weight-light
                      font-italic
                      align-content-start
                    >
                      <span class="font-italic body-1 text--darken-3 grey--text">{{ humanizeDate(item.date) }} by {{ item.name }} from {{ item.location }}</span>
                    </v-flex>
                  </v-layout>

                  <v-divider
                    v-if="index + 1 < inquiries.length"
                    :key="index"
                    class="grey lighten-2"
                    light
                    indent
                  />
                </template>
              </div>

              <div
                v-else
                class="pa-0 ma-0 pt-2 grey lighten-4"
                style="height:350px; overflow:hidden; overflow-x:hidden; box-shadow: inset 1px 0 2px 0 rgba(156,156,156,0.50);"
              >
                <v-flex
                  d-block
                  fill-height
                  justify-center
                >
                  <v-flex
                    d-flex
                    justify-center
                  >
                    <v-icon x-large>
                      mdi-message-alert-outline
                    </v-icon>
                  </v-flex>
                  <v-flex
                    d-flex
                    justify-center
                  >
                    No inquiries found on this account
                  </v-flex>
                </v-flex>
              </div>
            </div>
          </v-tab-item>
          <v-tab
            key="tab3"
            ripple
          >
            Billing History
          </v-tab>
          <v-tab-item
            key="tab3"
          >
            <div
              style="position: relative;"
              class="pa-0 ma-0 grey lighten-4"
            >
              <div
                v-if="billings && billings.length > 0"
                class="pa-0 ma-0 pt-2"
                style="height:350px; overflow:auto; overflow-x:hidden; box-shadow: inset 1px 0 2px 0 rgba(156,156,156,0.50);"
              >
                <template
                  v-for="(item, index) in billings"
                >
                  <v-layout
                    :key="item.id"
                    xs12
                    wrap
                    pl-4
                    pr-4
                    justify-center=""
                  >
                    <v-flex
                      md2
                      xs6
                      d-flex
                      pb-0
                      justify-center
                    >
                      <span class="font-italic body-1 text--darken-3 grey--text">  Nº - {{ item.id }}</span>
                    </v-flex>
                    <v-flex
                      xs6
                      d-flex
                      md2
                      justify-center
                    >
                      <span class="font-italic body-1 text--darken-3 grey--text"> {{ invoiceDate(item.date) }}</span>
                    </v-flex>
                    <v-flex
                      d-flex
                      md2
                      justify-center
                    >
                      <span class="font-italic body-1 text--darken-3 grey--text">   {{ item.staff }} </span>
                    </v-flex>
                    <v-flex
                      d-flex
                      md3
                      justify-center
                    >
                      <span class="font-italic body-1 text--darken-3 grey--text">  {{ item.status }} </span>
                    </v-flex>
                    <v-flex
                      d-flex
                      md3
                      justify-center
                    >
                      <span class="font-italic body-1 text--darken-3 grey--text">  {{ item.amount }} </span>
                    </v-flex>
                  </v-layout>

                  <v-divider
                    v-if="index + 1 < billings.length"
                    :key="index"
                    class="grey lighten-2"
                    light
                    indent
                  />
                </template>
              </div>

              <div
                v-else
                class="pa-0 ma-0 pt-2 grey lighten-4"
                style="height:350px; overflow:hidden; overflow-x:hidden; box-shadow: inset 1px 0 2px 0 rgba(156,156,156,0.50);"
              >
                <v-flex
                  d-block
                  fill-height
                  justify-center
                >
                  <v-flex
                    d-flex
                    justify-center
                  >
                    <v-icon x-large>
                      mdi-message-alert-outline
                    </v-icon>
                  </v-flex>
                  <v-flex
                    d-flex
                    justify-center
                  >
                    No invoices found on this account
                  </v-flex>
                </v-flex>
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data () {
    return {
      company_details: {
        description: [
          0, {
            id: ''
          }
        ]
      },
      interactions: '',
      inquiries: [],
      billings: []
    }
  },
  computed: {
    company () {
      return this.$store.getters.company
    },
    token () {
      return this.$store.getters.token
    }
  },

  mounted() {
      this.updateDetails()
  },

  updated () {
    this.updateDetails()
  },
  methods: {
    humanizeDate (date) {
      return this.$moment(date).fromNow()
    },
    invoiceDate (date) {
      return this.$moment(date).format('MMM Do YYYY')
    },
    updateDetails(){
      if (this.company_details.description[0].id == '' || this.company_details.description[0].id !== this.company) {
      this.$http.get(this.$api_url + 'companies/detail/' + this.company, {
        headers: {
          'OAuth-Token': this.token
        }
      })
        .then(response => {
          this.company_details = response.data
          this.active_tab = 'tab1'
          this.$http.get(this.$api_url + 'companies/interactions/' + response.data.description[0].idcrm, {
            headers: {
              'OAuth-Token': this.token
            }
          })
            .then(response => {
              this.interactions = response.data
            })
        })

      this.$http.get(this.$api_url + 'companies/inquiries/' + this.company, {
        headers: {
          'OAuth-Token': this.token
        }
      })
        .then(response => {
          this.inquiries = response.data
        })

      this.$http.get(this.$api_url + 'companies/billings/' + this.company, {
        headers: {
          'OAuth-Token': this.token
        }
      })
        .then(response => {
          this.billings = response.data
        })
    }
    }
  }

}
</script>
