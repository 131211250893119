<template lang="pug">
    v-container(px-0 py-0 my-4 :class="ticketColor(public)" )
        v-row(no-gutters class="xp-comment-header")
            v-col()
                |{{ author }}
            v-col(class="text-right")
                |{{ formatDate(date) }}
        v-row(no-gutters class="pa-2")
            v-col
                p(v-html="body" class=" tw-font-normal" )
                
            
        
</template>
<script>
export default {
    props:{
        author:{
            type: String,
            default: ''
        },
        date:{
            type: String,
            default: ''   
        },
        public:{
            type: Boolean,
            default: false,
        },
        body: {
            type: String,
            default: '',
        }
    },
    data(){
        return{
            
        }
    },
    methods: {
        formatDate: function(date) {
            return this.$moment(date).format('MMM Do, h:mma')
        },
        ticketColor: function(isPublic){
            switch (isPublic) {
                case true:
                    return "xp-public-comment"
                    break;
            
                case false:
                    return "xp-private-comment"
                    break;
            }
        }
    },
}
</script>

<style lang="css">
.xp-public-comment {
    @apply tw-bg-white tw-rounded tw-overflow-hidden tw-border tw-border-gray-400 tw-text-gray-800;
}

.xp-public-comment .xp-comment-header {
    @apply tw-bg-gray-300 tw-text-gray-600 tw-font-normal tw-font-semibold tw-p-2 tw-border-b tw-border-gray-400; 
}

.xp-private-comment {
    @apply tw-bg-yellow-200 tw-overflow-hidden tw-border tw-border-yellow-400 tw-text-gray-700;
}

.xp-private-comment .xp-comment-header {
    @apply tw-bg-yellow-300 tw-p-2 tw-font-normal tw-font-semibold tw-text-yellow-600 tw-border-b tw-border-yellow-400;
}
</style>