<template>
  <v-app class=" tw-min-h-screen">
    <!--
    <core-toolbar v-if="loggedIn == true"/>

    <core-drawer v-if="loggedIn == true"/>
-->
    <core-view />
  </v-app>
</template>
<script>
export default {
  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn
    },
    token () {
      return this.$store.getters.token
    }
  }
}
</script>
<style lang="scss">
@import "@/styles/index.scss";

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>

