<template lang="pug">
    v-container(fluid py-1 px-0 class=" tw-font-normal overflow-hidden"  )
        v-card(outlined flat )
            v-container(fluid py-1 px-4 class="tw-bg-white tw-border-gray-500")
                v-row(no-gutters align="center" class=" tw-text-center flex-nowrap")
                    v-col( cols="1" class=" tw-font-extrabold tw-text-blue-800")
                        |{{ data.number }}
                    
                    v-col( cols="2")
                        |{{ formatDate(data.received_on) }}

         
                    
                    v-col( class="tw-text-left")
                        v-avatar
                            v-icon mdi-account-circle-outline
                        |{{ data.requester_name }}
                    v-spacer
                    
                    
                    v-col(cols="1" class=" tw-capitalize")
                        v-chip( pill class="tw-ml-0 tw-w-24 tw-font-semibold"  :color="ticketColor(data.status)")
                            v-avatar(color="indigo darken-2 indigo--text text--lighten-5 " class="tw-py-4 tw-pl-4 tw-pr-4 tw-font-semibold" left)
                                span(class="tw-text-xs") {{ data.user_initials }}
                            span(class="tw--ml-1 tw-text-xs tw-tracking-wider tw-uppercase") {{ data.status }}

                    
                    v-col(cols="1")
                        div(v-if="data.opportunity")
                            v-chip( pill class="tw-ml-0" color="light-green darken-2 white--text tw-font-semibold")
                                v-avatar(color="grey darken-3 darken-1" class="tw-py-4 tw-pl-4 tw-pr-4" left)
                                    span(class="white--text tw-font-semibold tw-text-xs") LEO 
                                |Prospecting
                       
</template>
<script>
export default {
    props:{
        data:{
            type: Object,
            default: null
        }
    },
    methods: {
        ticketColor: function(status){
            switch (status) {
                case "open":
                    return "red lighten-4 red--text text--darken-3"
                    break;
                case "pending":
                    return "blue lighten-2 white--text text--darken-4"
                    break;
                case "solved":
                    return "grey lighten-2 text-grey text--darken-3"
                    break;
                case "closed":
                    return "grey lighten-2 text--grey text--darken-3"
                    break;
                default:
                    break;
            }

        },
        formatDate: function(date){
            return this.$moment(date).format('MMM Do, h:mma')
        }
    },
}
</script>