<template lang="pug">
   v-container( fluid py-0 px-0 class="tw-bg-gray-300 tw-shadow-inner tw-max-h-screen fill-height")
       v-row(no-gutters class="tw-self-start tw-h-full"  )
        v-slide-x-transition(mode="out-in")
            v-col( class="grey lighten-4 tw-shadow-md tw-border-gray-400 tw-border-r" )
                v-tabs(v-model="tab" background-color="transparent")
                    v-tab(key="all" )
                        |All
                v-tabs-items(v-model="tab" class="grey lighten-4 tw-overflow-hidden tw-h-full ")
                    v-tab-item(key="all" class="tw-h-full tw-overflow-hidden" )
                        v-container( fluid class="tw-items-start tw-h-full tw-overflow-hidden  " )
                            div( class="tw-self-start tw-h-full tw-overflow-hidden  ")
                                v-row(no-gutters)
                                    v-col()
                                        v-container(fluid py-1 px-4)
                                            v-row(no-gutters align="center" class="tw-font-medium tw-text-sm tw-text-center flex-nowrap grey--text text--darken-2 ")
                                                v-col( cols="1" )
                                                    |ZD Ticker
                                                v-col( cols="2")
                                                    |Received
                                         
                                                v-col( )
                                                    |Prospect
                                                v-spacer
                                                v-col(cols="1")
                                                    |Helpdesk
                                                v-col(cols="1")
                                                    |Sales
                                v-row(  no-gutters)
                                    v-col(class="tw-overflow-scroll tw-max-h-main " )
                                        div(v-for="ticket in tickets" )
                                            div(@click="showDetail(ticket.number)" class="tw-cursor-pointer")
                                                xprt-MktLeads-leadListItem(
                                                    :data = "ticket"
                                                )
                                        div(class=" tw-pb-24")


        v-slide-x-reverse-transition
            v-col(class="grey lighten-3 tw-shadow-inner" cols="5" v-show="detailView"  )
                v-row(no-gutters class="fill-height align-start"  )
                    v-col( v-if="detailView" class=" fill-height  tw-w-64")
                        xprt-MktLeads-leadDetail(:ticketNumber = "ticketNumber")
            
</template>
<script>
export default {
    props:{
            campaign: {
                type: Number,
                default: null
            },
            date: {
                type: String,
                default: null
            },
    },
    data() {
        return {
            tab: null,
            detailView: false,
            ticketNumber: null,
            tickets:{}
        }
    },
    watch: {
        date: function(){
            this.updateTicketList()
        },
        campaign: function(){
            this.updateTicketList()
        }
    },
    mounted() {
        this.updateTicketList()
    },
    methods: {
        showDetail(ticketNumber){
            this.ticketNumber = ticketNumber
            this.detailView = true
        },
        hideDetail(){
            this.detailView = false
        },
        updateTicketList(){
            this.detailView = false
            const headers = {
                'Content-Type': 'application/json',
                'OAuth-Token': this.$store.getters.token 
            }
            this.$http.post(this.$api_url + 'campaigns/tickets', {
             token: this.token,
             date_from: this.date,
             campaign_id: this.campaign

            }, { headers: headers })
            .then(response => {
                this.tickets = response.data
            })

         }
  } 
}
</script>