// https://vuex.vuejs.org/en/mutations.html
import { set } from '@/utils/vuex'

export default {
  //
  setTokenExpires: set('tokenExpires'),
  login (state, token, tokenExpires) {
    state.token = token
    state.tokenExpires = tokenExpires
  },
  logout (state, token) {
    state.token = null
  },
  temp (state, data) {
    state.temp = data
  }, 
  result (state, data) {
    state.result = data
  }
}
