// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'

// Components
import './components'

// Plugins
import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'

import colors from 'vuetify/es5/util/colors'

// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import Axios from 'axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueMoment from 'vue-moment'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import VueLottiePlayer from "vue-lottie-player";
import HighlightableInput from "vue-highlightable-input"
Vue.use(VueApexCharts)
Vue.component('Apexchart', VueApexCharts)
Vue.component('HighlightableInput', HighlightableInput);

// Sync store with router
sync(store, router)

Vue.prototype.$http = Axios
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueMoment)
Vue.use(VueLottiePlayer)
Vue.config.productionTip = false

// Env Variables
Vue.prototype.$api_url = process.env.VUE_APP_APIURL
Vue.prototype.$sugar_url = process.env.VUE_APP_SUGAR_URL

//Filters
Vue.filter("notNull", function(v){
  if(!v){
    return ""
  } else {
    return v
  }
})
Vue.use(vuetify, {
  theme: {
    secondary: colors.grey.darken1,
    accent: colors.shades.black,
    error: colors.red.accent3
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.query.token) {
      store.dispatch('registerToken', to.query.token)
        .then(response => {
          next()
        })
        .catch(error => {
          console.log(error)
          next({
            name: 'Login'
          })
        })
    } else if (!store.getters.loggedIn) {
      next({
        name: 'Login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        name: 'Leads'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
