import Axios from 'axios'
import { reject, result } from 'lodash';
import moment from 'moment'
//import * as API from "@/services/API"

// https://vuex.vuejs.org/en/actions.html

const apiClient = Axios.create({
  baseURL: process.env.VUE_APP_APIURL,
  withCredentials: true, // required to handle the CSRF token
  headers: {
    'Content-Type': 'application/json',
    'OAuth-Token': localStorage.getItem('access_token')
  }
});

export default {
  //
  login (context, credentials) {
    return new Promise((resolve, reject) => {
      Axios.post(process.env.VUE_APP_APIURL + 'login', {
        username: credentials.email,
        password: credentials.password
      })
        .then(response => {
          const token = response.data.access_token
          const tokenExpires = moment().add(response.data.expires_in, 's').format('x')
          const refreshToken = response.data.refresh_token
          localStorage.setItem('access_token', token)
          localStorage.setItem('refresh_token', refreshToken)
          localStorage.setItem('token_expires', tokenExpires)
          context.commit('login', token)
          context.commit('setTokenExpires', tokenExpires)
          resolve()
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  logout (context) {
    if (context.getters.loggedIn) {
      localStorage.removeItem('access_token')
      context.commit('logout')
    }
  },
  isTokenValid (context) {
    const isTokenValid = context.getters.tokenExpires > moment().subtract(2, 'm')
    return isTokenValid
  },
  registerToken (context, token) {
    return new Promise((resolve, reject) => {
      Axios.post(process.env.VUE_APP_APIURL + 'validate-token', {
        token: token
      }).then(response => {
        localStorage.setItem('access_token', token)
        context.commit('login', token)
        resolve()
      }).catch(error => {
        context.commit('logout', token)
        reject(error.message)
      })
    })
  },
  updateCompany (context, id) {
    context.commit('app/setCompany', id)
  },
  storeData (context, data) {
    context.commit('temp', data)
  },
  getKeywords(context, payload){
    return apiClient.post('keywords/analyze-text', payload)
    .then(response =>{
        return response.data
    })
    .catch(error => {
        return error
        //context.commit("SET_ERROR", error, {root: true})
    })
  }
}
