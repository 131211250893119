/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/login',
    name: 'Login',
    view: 'auth/Login',
    meta: {
      requiresVisitor: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    view: 'auth/Logout'
  },
  {
    path: '/leads',
    name: 'Leads',
    view: 'Leads',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/zendesk',
    name: 'zendesk',
    view: 'zendesk/ListTickets',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/zendesk/ticket/:id',
    name: 'viewticket',
    view: 'zendesk/ViewTicket',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'custom_reports',
    view: 'reports/Main',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: () => import("@/views/reports/LastCall/LastCall.vue"),
        name: 'lastCall',
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'no-planned-call',
        component: () => import("@/views/reports/NoPlannedCall/NoPlannedCall.vue"),
        name: 'noPlannedCall',
        meta: {
          requiresAuth: true,
        }
      }
    ]
  },
  {
    path: '/marketing/leads',
    name: 'reports_marketing_leads',
    view: 'reports/MarketingLeads/MarketingLeads',
    meta: {
      requiresAuth: true 
    }
  },
  {
    path: '/keywords/bulk-create',
    name: 'keywords_bulk_create',
    view: 'keywords/BulkCreate',
    meta: {
      requiresAuth: true 
    }
  },
  {
    path: '/detail',
    name: 'detail',
    view: 'Detail'
  }

]
